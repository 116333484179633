import "./admin/jquery";
import "bootstrap/dist/js/bootstrap.js";

import Rails from "rails-ujs";
Rails.start();

import "./admin/javascripts/old/folders.js";

import "./admin/autosize.js";
import "./admin/pwstrength.js";
import "./admin/datetimepicker.js";
import "./admin/sortable.js";
import "./admin/tinymce.js";
import "./admin/datatables.js";
import "./admin/menus.js";
import "./admin/pages.js";
import "./admin/misc.js";
import "./admin/slider.js";

import "@fortawesome/fontawesome-free/css/all.css";
